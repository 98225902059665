import { render } from "./PanelEmpleado.vue?vue&type=template&id=5b731c8e&scoped=true"
import script from "./PanelEmpleado.vue?vue&type=script&lang=js"
export * from "./PanelEmpleado.vue?vue&type=script&lang=js"

import "./PanelEmpleado.vue?vue&type=style&index=0&id=5b731c8e&lang=css"
import "./PanelEmpleado.vue?vue&type=style&index=1&id=5b731c8e&scoped=true&lang=css"
import "./PanelEmpleado.vue?vue&type=style&index=2&id=5b731c8e&lang=scss&scoped=true"
script.render = render
script.__scopeId = "data-v-5b731c8e"

export default script
<template>
    <div class="p-card px-5">
        <div class="flex justify-content-end">
            <div class="py-2"/>
        </div>
        <div class="flex align-items-center">
            <div class="symbol symbol-60 symbol-circle symbol-light-primary mr-3">
                <div class="symbol-label">
                    <i class="pi pi-user text-6xl" />
                </div>
            </div>
            <div>
                <a class="font-bold text-lg text-gray-700 hover:text-primary uppercase">{{empleado.emp_nombres_apellidos}}</a>
                <div class="text-muted font-semibold">{{empleado.emp_puesto}}</div>
                <div class="text-muted font-semibold">{{empleado.emp_unidad}}</div>
            </div>
        </div>
        <div class="pt-4">
            <div class="flex align-items-center justify-content-between mb-2">
                <span class="font-bold mr-1">Regional:</span>
                <a class="text-muted hover:text-primary">{{empleado.emp_sede}}</a>
            </div>
            <div class="flex align-items-center justify-content-between mb-1">
                <span class="font-bold mr-1">Email:</span>
                <a class="text-muted hover:text-primary">{{empleado.emp_correo}}</a>
            </div>
        </div>
        <div class="flex-grow-1 pt-4">
            <div  class="flex justify-content-end">
                <Button :label="selectedCiclo.cil_descripcion" icon="pi pi-chevron-down" iconPos="right" class="p-button-sm btn btn-bg-light text-gray-800" 
                @click="selectedCiclo_onToggle" aria-haspopup="true" aria-controls="overlay_menu"/>
                <Menu id="overlay_menu" ref="menu" :model="cicloItemsComputed" :popup="true" />
            </div>
            <div class="pt-1">           
                <div class="flex aling-items-center" v-if="loadingAsignacion">
                    <ProgressSpinner style="width:50px;height:50px" strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s"/>
                </div> 
            <!-- Style for Wizard 2-->
                <div class="wizard-nav" v-else>
                    <div class="wizard-steps" >
                        <div class="wizard-step cursor-pointer" >
                            <div class="wizard-step" :data-wizard-state="selectedDocenteGrupo == dataGrupo ? 'current' : 'pending'" 
                            v-for="dataGrupo in docenteGrupoList" :key="dataGrupo" @click="selectedMateria_onClick(dataGrupo)"> 
                            <div class="wizard-wrapper">
                                    <div class="wizard-icon">
                                        <LayerIco  :size="'svg-icon svg-icon-2tx'" :color="''"/>
                                    </div>
                                    <div class="wizard-label">
                                        <h3 class="wizard-title my-0" >{{dataGrupo.asignatura.plm_mat_nombre}}</h3>
                                        <span class="wizard-desc">{{dataGrupo.hor_descripcion}}</span>
                                        <span class="wizard-desc">{{dataGrupo.hor_comentario}}</span>
                                    </div>                                           
                            </div>
                            </div>
                        </div>
                    </div>     
                </div>
                
            </div>
        </div>
    </div>
</template>

<script>
import {Layer} from "@/components/svg/_svgHelper.js"
import EmpleadoService from '@/service/empleadoService'
import GeneralService from '@/service/generalService'
import methods from "@/utilities/generalMethods.js"

import GuiaDocenteService from '@/service/guiaDocenteService'

export default {
    empleadoService: null,
    generalService : null,
    guiaDocenteService : null,


    props:{
        Code:{
            type: Number,
            default:0
        }
    },
    emits:["selectedCiclo_onEmit", "selectedGrupo_onEmit"],
    data(){
        return {
            empleado: {},

            cicloList: [],
            selectedCiclo : {},
            loadingAsignacion: false,
            showMoreCiclo : 0,

            docenteGrupoList: [],
            selectedDocenteGrupo:{},
        }
    },
    async created(){
        this.generalService =  new GeneralService(this.axios)
        this.empleadoService =  new EmpleadoService(this.axios)
        this.guiaDocenteService =  new GuiaDocenteService(this.axios)
        this.selectedCiclo =  this.$store.getters.getCicloVigente
    },
    async mounted(){
        try{
            this.loadingAsignacion =  true;
            this.cicloList = await this.generalService.getCiclo()
            this.empleado = await this.empleadoService.GetById(this.Code) 
            this.docenteGrupoList =  await this.guiaDocenteService.getGrupoByDocente(this.selectedCiclo.cil_codigo, this.Code)
            .then(res=> res)
            .catch(ex => console.log(ex));

            
            
        }catch(ex){
            var msg = methods.messageCode(ex)
            this.$toast.add({severity:'warn', summary:'¡Ocurrió un error!', detail:msg, life: 5000});
        }finally{
            this.loadingAsignacion  = false
        }
    },
    methods:{
        selectedCiclo_onToggle(event)
        {
            this.$refs.menu.toggle(event);
        },

        selectedMateria_onClick(grupo)
        {
            this.selectedDocenteGrupo = grupo
            this.$emit("selectedGrupo_onEmit", grupo)
        },
    },
    computed:{
         cicloItemsComputed(){
            //Combo/Select tipo de comentario
            var listMenu = []
            var tempData = [...this.cicloList]

            if(this.cicloList.length > 0){
                
                listMenu = tempData.slice(this.showMoreCiclo, 6).map((i) => {
                    return {
                        data : i,
                        icon:"pi pi-angle-double-right text-danger",
                        label : i.cil_descripcion,
                        command:async (event)=> {
                            this.loadingAsignacion = true
                            try{
                                this.selectedCiclo =  event.item.data
                                this.docenteGrupoList =    await this.guiaDocenteService.getGrupoByDocente(this.selectedCiclo.cil_codigo, this.Code);
                                //Load Result ciclo
                                this.$emit("selectedCiclo_onEmit",event.item.data)
                            }catch(ex){
                                    var msg = methods.messageCode(ex)
                                    this.$toast.add({severity:'error', summary:'¡OOOPS!', detail:msg, life: 3500});
                            }finally{
                                this.loadingAsignacion = false
                            }
                        }
                    }
                })
                listMenu.push({ 
                    separator  : true},
                    {
                        label: "Nada que mostrar",
                        icon:"pi pi-exclamation-circle text-danger",
                        command: () => {
                           
                        }    
                    }
                )
            }
            else 
                return [
                    { separator  : true},
                    {label: "Sin contenido"}
                ]

            return listMenu;
        },
    },
    components:{
        "LayerIco" : Layer     
    }
}
</script>
<template>
    <div class="flex  flex-column border-round p-1  border-dashed-custom my-1 " :class="classType">  
        <div class="flex flex-shrink-0">
            <BulbIco :size="'svg-icon-2tx'" :color="'svg-icon-'+ type" class="mr-3"/>
            <div class="flex justify-content-between align-items-center flex-grow-1 flex-wrap md:flex-nowrap">
                <div class="mb-3 mb:mb-0 font-semibold">
                        <h5 class="font-bold py-0 my-0"  :class="'text-'+ type">{{customTitle}}</h5>
                    <div class="text-base text-gray-600 pr-2 "> {{customMessage}} </div>
                </div>
            </div>
            <slot name="buttons"></slot>
        </div> 
    </div>
</template>


<script>

//Icons
import {Bulb} from "@/components/svg/_svgHelper.js"

const ORIGIN = {
    INFORME : "Informe",
    RENDIMIENTO : "Rendimiento"
}
export default {
    
    props:{
        resource: {
            type: String, 
            default : ""
        },
        type:{
            type: String, 
            default : ""
        },
        title:{
            type: String, 
            default : ""
        },
        message:{
            type: String, 
            default : ""
        }
    },
    data(){
        return{
            origin : ORIGIN,
            current_origin : ORIGIN.INFORME
        }
    },
    created(){
        this.current_origin =  this.origin[this.resource]

        
    },
    mounted(){
        
    },
    computed:{
        customTitle(){
            var text = ""
            if(this.current_origin == this.origin.INFORME){
                if(this.type == "warning"){
                    text ="¡El informe no se ha finalizado!"
                }else if(this.type =="success"){
                    text ="¡El informe ha sido validado!"
                }else{
                    text ="¡El informe ha sido autorizado!"
                }
            } else if(this.current_origin == this.origin.RENDIMIENTO){
                if(this.type == "warning"){
                    text ="¡El informe no se ha finalizado!"
                }else{
                    text ="¡El informe se ha enviado!"
                }
            }

            return text;
        },
        customMessage(){
            var text =  ""
            if(this.current_origin == this.origin.INFORME){
                if(this.type == "warning"){
                    text = "Revise los comentarios y/o observaciones, organicelos y elimine los repetidos"
                }else if(this.type =="success"){
                    text = "Solicite autorización a la decanatura/coordinación correspondiente antes de su impresión."
                }else{
                    text = "Este es un informe válido listo para su impresión."
                }
            }else if(this.current_origin == this.origin.RENDIMIENTO){
                if(this.type == "warning"){
                    text = "Ingrese la información necesaria a enviar en el informe final de rendimiento acádemico"
                }else{
                    text = "Este es un informe válido listo para su impresión y se ha enviado a la decanatura correspondiente"
                }
            }
            return text;
        },


        classType(){
            return  `bg-light-${this.type} border-${this.type}`
        }
    },
    components:{
        "BulbIco" : Bulb,
    }
}
</script>
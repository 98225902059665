<template>
    <!--Begin: Dialogo Impresión -->
    <Dialog v-model:visible="isPrintDialog" :modal="true" class="p-dialog-maximized pb-2" @hide="dialog_onHide()">
        <template #header class="">
            <div class="flex my-0">
                <div class="h-100 bg-primary p-0 mr-1" style="width:2px"></div>
                <h5 class="text-primary my-1">{{Title}} </h5>
            </div>
        </template>
        <div class="h-screen flex flex-column align-items-center justify-content-center" v-if="generating">
            <ProgressSpinner />
            <span  class="text-base">Generando archivo...</span>
        </div>
        <object :data="printElement" type="application/pdf"  scrolling="auto" class="w-full h-full">
            <embed :src="printElement" type="application/pdf" />
        </object>
    </Dialog> 
    <!--End: Dialogo Impresión -->
</template>


<script>
export default {
    props:{
        Title:{
            type: String, 
            default : ""
        },
        BLOB : null,
        Visible:{
            type : Boolean,
            default: false
        },
       
    },
    emits:["hide", "update:Visible"],
    data(){
        return{
            isPrintDialog : this.Visible,
            generating :  false,
            printElement : null
        }
    },
    async mounted(){
        try {
            this.generating =  true;
            const url = window.URL.createObjectURL(new Blob([this.BLOB.data], {type: 'application/pdf'}));
            this.printElement  = url + `#filename=Informe Desempeño.pdf`
        }catch(ex){
            this.$toast.add({severity:'info', summary:'¡Ocurrió un error!', detail:ex, life: 3000});
        }
        this.generating =  false;
    },
    methods:{
        dialog_onHide(){
            this.$emit('update:Visible', false)
            this.isPrintDialog =  false;
        }
    }
}
</script>
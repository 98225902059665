// JSDoc (http://usejsdoc.org/)
/** @module RendimientoAcademicoService */

export default class rendimientoAcademicoService{
    /**
     * @param axios Axios Instance
     */
     constructor(axios){
        this.axios =  axios;
    }


    /**
     * Obtener un rendimiento academico
     * @param {Integer} codigo  codigo de redimiento
     * @return {json} JsonObject
     */
     async getById(codigo) {
        return this.axios.get(`/RendimientoAcademico/${codigo}`).then(res => res.data);
    }

    /**
     * Obtener un rendimiento academico
     * @param {Integer} codhor  codigo de horario
     * @param {Integer} codcil  codigo de ciclo
     * @return {json} JsonObject
     */
     async getByHorario(codhor, codcil) {
        return this.axios.get(`/RendimientoAcademico/${codhor}/${codcil}`).then(res => res.data);
    }


    /**
     * Crear un informe de materia
     * @param {json} data Parámetros de Entrada.
     * @param {Date} data.rai_fecha  Fecha de presentacion
     * @param {Integer} data.rai_codhor  Codigo de horario
     * @param {String} data.rai_nomhor  codigo de ciclo
     * @param {String} data.rai_deschor  codigo de ciclo
     * @param {Integer} data.rai_codcil  nombre de ciclo
     * @param {String} data.rai_nomcil  Descripcion del grupo 
     * @param {Integer} data.rai_codemp  Codigo de empleado
     * @param {Integer} data.rai_computo  identificador de computo
     * @param {Integer} data.rai_avance  Porcentaje de avance
     * @param {DateTime} data.rai_descripcion  Descripcion de Desempeño
     * @param {Integer} data.rai_aprobados  Cantidad de Aprobados
     * @param {Integer} data.rai_reprobados  Cantidad de Reprobados
     * @param {Integer} data.rai_desercion  Cantidad de deserciones
     * 
     * @param {Integer} data.rai_codusr  Codigo de usuario
     * @param {json} data.asignatura  Asignatura datos
     * @param {Integer} data.asignatura.plm_codmat  Codigo de materia
     * * @param {Integer} data.asignatura.plm_codpla  Codigo de plan
     * @param {Int} data.rai_codigo  Codigo de Informe OUT
     * @return {json} JsonObject
     */
     async Upsert(data){
        return this.axios.post('/RendimientoAcademico',data, {
            header: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data);
    }

    /**
     * Actualizando un informe
     * @param {json} data Parámetros de Entrada.
     * @param {Date} data.rai_fecha  Fecha de presentacion

     * @param {Integer} data.rai_avance  Porcentaje de avance
     * @param {DateTime} data.rai_descripcion  Descripcion de Desempeño
     * @param {Integer} data.rai_aprobados  Cantidad de Aprobados
     * @param {Integer} data.rai_reprobados  Cantidad de Reprobados
     * @param {Integer} data.rai_desercion  Cantidad de deserciones
     * @param {Int} data.rai_codigo  Codigo de Informe OUT
     * @return {json} JsonObject
     */
    async update(codigo,data) {
        return this.axios.put(`/RendimientoAcademico/${codigo}`, data, {
            header: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data);
    }

    /**
     * Imprimir/Extraer archivo de Informe final (Finalizar)
     * @param {Integer} codigo  Codigo de Informe
     * @return {blob} blobObject
     */
    async printInforme(codigo){
    return this.axios({
        url: `/RendimientoAcademico/Print/${codigo}`,
        method: 'GET',
        responseType: 'blob', // important
    }).then((res) =>res);
}
}
<template>
    <Dialog v-model:visible="isModalVisible" :style="{width: '640px'}" :modal="true" class="p-fluid" @hide="cancel_onClick($event)" :closeOnEscape="false">
        <template #header >
            <div class="flex flex-column align-items-center w-full">
                <h4 class="font-semibold text-gray-900 mb-0 pb-0">Informe de desarrollo de la asignatura</h4>
                <small  class="text-muted text-base  font-semibold text-center">y rendimiento académico de los estudiantes</small>
            </div>
        </template>
       
        <!--Begin: Content -->
        <div class="h-3px w-100 p-0 m-0 bg-primary"></div>
        <div class="flex mt-2" > 
            <div class="flex-shrink-1  hidden md:inline-flex">
                <div class="flex flex-column align-items-center">
                    <div class="symbol symbol-50 symbol-lg-120 symbol-light-primary shadow-3 mb-2">
                        <span class="text-2xl symbol-label font-bold">I{{rendimientoAcademico.rai_computo}} </span>
                    </div>
                    <small class="font-semibold text-primary text-xs">INFORME</small>
                </div>
            </div>
            <div class="flex-grow-1 ml-3">
                <div class="flex align-items-center justify-content-between flex-wrap mt-1 flex-wrap">
                    <div class="mr-1">
                        <a href="javascript:void(0)" class="font-bold mr-2 text-lg text-gray-800 hover:text-primary uppercase" >{{Materia.asignatura.plm_mat_nombre}}</a>
                        <div class="flex flex-column  md:flex-row flex-wrap my-2 text-xs ">
                            <a href="javascript:void(0)" class=" flex align-items-center text-muted font-semibold hover:text-primary mb-2  mr-2"> 
                                <i class="pi pi-th-large pi-fw  mr-1"></i> <span> {{Materia.facultad.fac_nombre}}</span></a>
                            <a href="javascript:void(0)" class="flex align-items-center text-muted font-semibold hover:text-primary  mb-2 mr-2"> 
                                <i class="pi pi-shield pi-fw  mr-1"></i> {{Materia.carrera.car_nombre}}</a>
                        </div>
                    </div>  
                </div>
                <div class="flex justify-content-between">
                     <a href="javascript:void(0)" class="label label-lg label-inline font-semibold ml-1 ">Grupo: {{Materia.hor_descripcion}} <br> {{Materia.hor_comentario}} </a>
                    <div class="flex flex-column">
                        <div class="flex align-items-center flex-lg-fill justify-content-center">
                            <span class="mr-2">
                                <i class="pi pi-user text-4xl  text-primary"></i>
                            </span>
                            <a href="javascript:;" class=" text-primary font-semibold mx-2 uppercase">{{rendimientoAcademico.rai_inscritos}}</a>
                        </div>
                        <small class="text-center text-xs"> Inscritos</small>
                    </div>
                   
                </div>
            </div>
        </div>

        <Divider class="p-0 my-2"/>

        <div class="mt-2">
            <form @submit.prevent="upsertInforme_onHandleSubmit(!v$.$invalid)" class="p-fluid mt-4" autocomplete="off" :style=" IsClosedComputed ? 'pointer-events: none;' : ''">
                <div class="formgird grid ">
                    <div class="field col">
                        <label for="rai_fecha" class="font-semibold" :class="{'p-error':submitted}">Fecha</label>
                        <Calendar id="rai_fecha" :showIcon="true" :manualInput="false" dateFormat="dd/mm/yy" :showTime="false" v-model="rendimientoAcademico.rai_fecha"/>
                    </div>

                    <!--** Only Edit Mode  -->
                    <div class="field col-5  md:col-5 text-right" >
                        <label for="rai_nivel" class="font-semibold"  >Cómputo</label>
                        <div class="flex  justify-content-end"> 
                            <SelectButton v-model="rendimientoAcademico.rai_computo" :options="computoOptions">
                                <template #option="slotProps">
                                    <span>{{slotProps.option}}</span>
                                </template>
                            </SelectButton>
                        </div>
                    </div>
                </div>
                <!--Begin For: Seccion -->
                <div class="p-card px-4 mb-3 border-round  border-dashed-custom " v-for="(seccion,idx_sec) in rendimientoAcademico.revision" :key="idx_sec">
                    <div class="p-card-title ">
                        <div class="card-header w-100 justify-content-center">
                            <div class="card-title">
                                <div class="flex flex-column align-items-center">
                                    <h5 class="ml-3 my-0 font-semibold text-gray-900 text-lg flex align-items-center">{{seccion.rse_nombre}}</h5>
                                    <div class="text-gray-500 text-sm font-semibold mx-3"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                     <!--Begin: Detalle Seccion 0 -->
                    <div class="formgird grid " v-if="idx_sec == 0">
                        <!--** Only Edit Mode  -->
                        <div class="col">
                            <div class="field" >
                            <label for="nivel_desc" class="font-semibold">Descripción del avance</label>
                            <Textarea id="nivel_desc" v-model="rendimientoAcademico.rai_descripcion" class="w-full" placeholder="Describa brevemente el nivel de avance que lleva en el desarrollo de la asignatura, según el programa y la planificación…" :autoResize="true"/>
                        </div>
                        </div>
                        <div class="field col-12 md:col-4 text-right" >
                            <label for="rai_nivel" class="font-semibold"  >Nivel de Avance</label>
                            <div class="flex  justify-content-end"> 
                                <div class="p-inputgroup w-10rem">
                                    <span class="p-inputgroup-addon">
                                        <i class="pi pi-percentage text-primary"></i>
                                    </span>
                                    <InputNumber  for="rai_nivel"
                                        mode="decimal" locale="en-US" :minFractionDigits="2"
                                        v-model="rendimientoAcademico.rai_avance"   
                                        inputClass="text-right" />
                                </div>
                            </div>
                            <small class="p-error text-xs">* Porcentaje %</small>
                        </div>
                    </div>
                    <!--Begin: Detalle Seccion 1 -->
                    <div class="formgrid grid" v-else>
                        <div class="col-12 my-2">
                            <span class="text-sm text-danger font-semibold ">* Cantidad de estudiantes según criterios de redimiento</span>
                        </div>
                        <!--Card -->
                        <div class="field col-12 md:col-4 ">
                            <label for="ras_aprobado" class="font-semibold">Aprobados</label>
                            <InputNumber id="ras_aprobado" 
                                :useGrouping="false"
                                v-model="rendimientoAcademico.rai_aprobados"  inputClass="text-center" placeholder="0"/>
                        </div>
                        <!--Card -->
                        <div class="field col-12 md:col-4 ">
                            <label for="ras_reprobado" class="font-semibold">Reprobados</label>
                            <InputNumber id="ras_reprobado"  
                                  :useGrouping="false"
                                v-model="rendimientoAcademico.rai_reprobados" inputClass="text-center" placeholder="0"/>
                        </div>
                        <!--Card -->
                        <div class="field col-12 md:col-4 ">
                            <label for="ras_desercion" class="font-semibold">Deserción</label>
                            <InputNumber id="ras_desercion"  
                                :useGrouping="false"
                                v-model="rendimientoAcademico.rai_desercion" inputClass="text-center" placeholder="0"/>
                        </div>
                    </div>
                    <!--End: Detalle Seccion -->
                    <div class="flex flex-column mt-3" v-for="(criterio,idx_crit) in seccion.criterioList" :key="idx_crit">
                        <h5 class="ml-3 my-0  font-semibold text-gray-900 text-lg flex align-items-center">{{criterio.rce_enunciado}}</h5>
                        <div class="flex flex-column " v-for="(esc, idx_esc) in criterio.escalaList" :key="idx_esc">
                            <div class="flex flex-row mb-1 p-2">
                                <span class=" bg-primary" style="width:2px"></span>
                                <div class="mx-2 w-full">
                                    <div class="flex">
                                        <div class="form-check form-check-solid">
                                            <input class="form-check-input" :name="'esc'+idx_sec+'_'+idx_esc+'_'+idx_crit" :checked="esc.selected" type="checkbox" :value="true" v-model="esc.selected"
                                                @click="selectedEscala_onClick(idx_sec+'_'+idx_esc+'_'+idx_crit)"
                                            >
                                        </div>
                                        <div class="flex flex-column w-100">
                                            <a href="javascript:;" class="text-primary font-bold block hover:text-blue-500">{{esc.ree_descripcion}}</a>                                            
                                        </div>
                                    </div>
                                    <div class="formgrid grid" v-if="esc.selected">
                                        <div class="field  col-12">
                                            <label for="rar_descripcion" class="font-semibold">Descripción</label>
                                            <Textarea id="rar_descripcion" class="w-full " placeholder="Describa..." :autoResize="true" :ref="'esc'+idx_sec+'_'+idx_esc+'_'+idx_crit"
                                                v-model="esc.rar_descripcion"
                                                />
                                        </div>
                                        <div class="field col-12 " v-if=" esc.rve_req_comentario">
                                            <label for="rar_comentario" class="font-semibold">Estrategia</label>
                                            <Textarea id="rar_comentario" class="w-full " placeholder="¿Qué estrategia ha utilizado para atender dicha situación?" :autoResize="true"
                                                v-model="esc.rar_comentario"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
                <!-- Control Buttons -->
                <div class="flex justify-content-center mt-3"  v-if="!IsClosedComputed">
                    <Button label="Cancelar" class="p-button-text p-button-danger w-10rem mx-2 font-semibold" @click="cancel_onClick($event)" />
                    <Button type="submit" :label=" IsCreatedComputed ? 'Actualizar' : 'Crear'" class="mt-2 w-10rem" :loading="loadingCreate"/>
                </div>
            </form>
        </div>
         <!--End: Content -->
        <template #footer >
            <Divider/>
            <div class="flex mt-1">
                <div class="block font-bold text-left">
                    <label class="">Este formulario deberá completarse después que el docente sube las notas al sistema</label>
                    <div class="text-sm text-gray-400">La fecha de presentación del formulario deberá ser la misma que para subir las notas en cada cómputo o periodo en que se divida el ciclo académico
                        <span class="text-xs">► Ref.{{rendimientoAcademico.rai_codigo}} ◄</span>
                    </div>
                </div>
            </div>
        </template> 
    </Dialog>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";

import moment from 'moment'
import methods from "@/utilities/generalMethods.js"

const delay = ms => new Promise(res => setTimeout(res, ms));
export default {
    emits:["hide","upsert_onEmit"],
    props:{
        Materia: {
            type: Object,
            default(){
                return {}
            }
        },
        Codigo:{
            type: Number,
            default: 0
        },
        Upsert:{
            type: Boolean,
            default: false
        },
        Service: null, //Rendimiento
        
    },
    
    setup: () => ({ v$: useVuelidate() }),
    data(){
        return{
            isModalVisible: this.Upsert,
            loadingCreate : false,
            submitted: false,

            cumputoOptions:[],
            rendimientoRevisionList: [],
            rendimientoAcademico:{}
        }
    },
    validations() {
        return {
        }
    },
    async created(){

        this.computoOptions = [1,2,3,4]

        this.rendimientoAcademico = await this.Service.getById(this.Codigo)
        if(this.Codigo == 0){
             this.fx_restartValue();
        }
            this.rendimientoAcademico.rai_fecha = new Date(this.rendimientoAcademico.rai_fecha)
    },
    async mounted(){
    },
    methods:{

        fx_restartValue(){
            this.rendimientoAcademico.rai_codhor = this.Materia.hor_codigo
            this.rendimientoAcademico.rai_comhor = this.Materia.hor_comentario
            this.rendimientoAcademico.rai_deschor= this.Materia.hor_descripcion
            this.rendimientoAcademico.rai_codcil = this.Materia.ciclo.cil_codigo
            this.rendimientoAcademico.rai_nomcil = this.Materia.ciclo.cil_descripcion

            this.rendimientoAcademico.rai_codemp = this.Materia.empleado.emp_codigo
            this.rendimientoAcademico.rai_inscritos = this.Materia.hor_total_ins
            this.rendimientoAcademico.rai_computo = 1
            this.rendimientoAcademico.rai_descripcion = ""

            this.rendimientoAcademico.rai_aprobados = 0,
            this.rendimientoAcademico.rai_aprobados = 0,
            this.rendimientoAcademico.rai_aprobados = 0
            this.rendimientoAcademico.rai_estado = "A"
            this.rendimientoAcademico.rai_codusr_creador = this.$store.getters.getUserId
            this.rendimientoAcademico.asignatura = this.Materia.asignatura
        },  
        cancel_onClick(evt){
            this.isModalVisible =  false;
            this.$emit("hide",evt);
        },


        async upsertInforme_onHandleSubmit(event){
            try{
                this.loadingCreate =  true;
                this.submitted = true;
                if (!event) {
                    return;
                }

                var message = this.IsCreatedComputed ? "Se ha creado el informe" : "Se ha actualizado el informe"

                var dataRev = []
                this.rendimientoAcademico.revision.map((sec)=> {
                    sec.criterioList.forEach((cri)=> {
                        cri.escalaList.forEach(esc => {
                            if(esc.selected){
                                if(esc.rank == 1 || esc.rank == 2){
                                    if(esc.rar_descripcion == "" || !esc.rar_descripcion.trim())
                                        throw `Descripción no válida en  [${esc.ree_descripcion}]`;
                                }
                                if(esc.rank == 2){
                                    if(esc.rar_comentario == "" || !esc.rar_comentario.trim())
                                        throw `Comentario de estrategia no válida en  [${esc.ree_descripcion}]`;
                                }
                               dataRev.push(esc)     
                            }
                                
                        });
                    })
                });
                
                if(this.rendimientoAcademico.rai_aprobados > this.rendimientoAcademico.rai_inscritos)
                    throw `Cantidad de aprobados es mayor a inscritos`;
                else if(this.rendimientoAcademico.rai_reprobados > this.rendimientoAcademico.rai_inscritos)
                    throw `Cantidad de probados es mayor a inscritos`;
                else if(this.rendimientoAcademico.rai_desercion > this.rendimientoAcademico.rai_inscritos)
                    throw `Cantidad de deserciones es mayor a inscritos`;

                this.rendimientoAcademico.rai_fecha =  moment(this.rendimientoAcademico.rai_fecha).format("YYYY-MM-DD")

                this.rendimientoAcademico.rai_aprobados =   this.rendimientoAcademico.rai_aprobados || 0
                this.rendimientoAcademico.rai_reprobados = this.rendimientoAcademico.rai_reprobados || 0
                this.rendimientoAcademico.rai_desercion = this.rendimientoAcademico.rai_desercion || 0
                await this.Service.Upsert({
                    rendimientoAcademico : this.rendimientoAcademico,
                    rendimientoRevision : dataRev 
                }).then(res => this.rendimientoAcademico = res)

                this.$toast.add({severity:'info', summary:'¡Éxito!', detail:message, life: 5000});
                this.$emit("upsert_onEmit", this.Materia);
            }catch(ex){
                var msg = methods.messageCode(ex)
                this.$toast.add({severity:'warn', summary:'¡Ocurrió un error!', detail:msg, life: 5000});
            }finally{
                this.loadingCreate = false
            }
        },

        async selectedEscala_onClick(idx){
            /* eslint-disable no-unused-vars */
            await delay(100)
            var el = this.$refs["esc" + idx]
            if(el != null)
                el.$el.focus();
        },

        DateFormat(data) {
            return moment(data).format('DD/MM/YYYY')
        },
    }, 
    computed:{
        IsCreatedComputed(){
            return this.rendimientoAcademico.rai_codigo > 0
        },
        IsClosedComputed(){
            console.log( this.rendimientoAcademico.rai_estado )
            return this.rendimientoAcademico.rai_estado == "C"
        }
    },
    components:{

    }
}
</script>

<style lang="scss" scoped>

    .form-check {
        display: block;
        min-height: 1.5rem;
        padding-left: 2.25rem;
        margin-bottom: 0.125rem;
    }
    .form-check .form-check-input {
        float: left;
        margin-left: -2.25rem;
    }

    .form-check-input {
        width: 1.75rem;
        height: 1.75rem;
        margin-top: -0.125rem;
        vertical-align: top;
        background-color: #ffffff;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        border: 1px solid rgba(0, 0, 0, 0.25);
        appearance: none;
        color-adjust: exact;
        transition: background-color 0.15s ease-in-out, background-position 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    .form-check-input[type=checkbox] {
  border-radius: 0.45em;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #B5B5C3;
  outline: 0;
  box-shadow: none;
}
.form-check-input:checked {
  background-color: #009EF7;
  border-color: #009EF7;
}

.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 11' width='13' height='11' fill='none'%3e%3cpath d='M11.0426 1.02893C11.3258 0.695792 11.8254 0.655283 12.1585 0.938451C12.4917 1.22162 12.5322 1.72124 12.249 2.05437L5.51985 9.97104C5.23224 10.3094 4.72261 10.3451 4.3907 10.05L0.828197 6.88335C0.50141 6.59288 0.471975 6.09249 0.762452 5.7657C1.05293 5.43891 1.55332 5.40948 1.88011 5.69995L4.83765 8.32889L11.0426 1.02893Z' fill='%23FFFFFF'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}
</style>
<template>
    <div class="mx-auto ">
        <div class="grid ">
            <div class="col-12 md:col-5 lg:col-3">
                <CardEmpleadoInfo :Code="emp_codigo"  @selectedGrupo_onEmit="selectedGrupo_onClick($event)" @selectedCiclo_onEmit="selectedMateria = null; CURRENT_VIEW = VIEW.DESEMPENO"/>
            </div>
            <div class="col-12 md:col-7 lg:col-8">
                <div class="justify-content-start flex align-items-end mt-3 mb-4" >
                    <a href="javascript:;" class="pb-1  border-bottom-2 text-gray-500 font-bold text-xl hover:text-primary" :class="classTabView(VIEW.DESEMPENO)" @click="CURRENT_VIEW = VIEW.DESEMPENO">Informe observación docente</a>
                    <a href="javascript:;" class="pb-1 mx-4  border-bottom-2 text-gray-500 font-bold text-xl hover:text-primary" :class="classTabView(VIEW.DESARROLLO)" @click="CURRENT_VIEW = VIEW.DESARROLLO" >Informe desarrollo y rendimiento</a>
                </div>
                
                <!--Begin: TAB DESEMPEÑO -->
                <div class="grid" v-if="CURRENT_VIEW ==  VIEW.DESEMPENO">
                    <div class="col-12" v-if="desempenoInformeList.length == 0">
                        <div class="text-center shadow-2 p-3 h-full flex flex-column surface-card" style="border-radius: 6px">
                            <h5 class="mt-2">Ooops! No se encontrarón resultados</h5>
                            <p class="text-muted mb-0">Al parecer no hay ningun archivo en el ciclo seleccionado. El área correspondiente no ha generado un archivo válido.-</p>
                        </div>
                    </div>
                    <div class="col-12 md:col-6" v-for="(data,index) in desempenoInformeList" :key="index">
                        <div class="shadow-2 p-3 h-full flex flex-column surface-card widget-box " :class="'wb-'+statusClass(data)" style="border-radius: 6px">
                            <div class="flex justify-content-between flex-wrap ">
                                <div class="flex align-items-center">
                                    <div class="flex flex-column">
                                        <a class="font-bold text-gray-700 hover:text-primary uppercase">{{data.desempenoDocenteList[0].ddo_hor_descripcion}}</a>
                                        <div class="text-muted font-semibold">{{data.desempenoDocenteList[0].asignatura.plm_mat_nombre}}</div>
                                        <div class="text-muted font-semibold">{{data.desempenoDocenteList[0].carrera.car_nombre}}</div>
                                    </div>
                                </div>
                                <div class="flex">
                                    <div class="label label-lg label-inline font-semibold"
                                    :class="'label-light-' + statusClass(data)">
                                        <i class="pi pi-star inline-flex md:hidden"></i>
                                        <span class="hidden md:inline-flex">{{data.ddi_estado === 'P' ? 'Autorizado' : (data.ddi_estado === 'A' ? 'Aprobado' : 'En Elaboración') }} </span>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-column mt-3" >
                                <Button label="Imprimir" :loading="loadingPrint" icon="pi pi-print" 
                                class="p-button-outlined p-button-sm h-full" :class="'p-button-'+statusClass(data)" @click="printInforme_onClick(data)"  />
                            </div>
                        </div>
                        
                    </div>
                </div>
                <!--Begin: TAB RENDIMIENTO ACADEMICO -->
                <div class="grid" v-else>
                    <div class="col-12">
                        <div class="flex flex-column shadow-2 p-3 h-full  surface-card" style="border-radius: 6px">
                            <div class="flex flex-row">
                                <div class="flex align-items-center flex-column flex-grow-1" >
                                    <h5 class="pb-0 mb-0 ">Informe de desarrollo de la asignatura y rendimiento académico de los estudiantes</h5>
                                    <p class="text-muted mb-0">La fecha de presentación del formulario deberá ser la misma que para subir las notas en cada cómputo o periodo en que se divida el ciclo académico</p>
                                </div>
                                <div class="my-1">
                                    <span class="p-buttonset">
                                        <Button type="button" icon="pi pi-pencil" :label="IsCreatedComputed ? 'Editar' : 'Generar'" class=" p-button-outlined" @click="upsertInforme_onClick(null)"/> 
                                        <Button label="" icon="pi pi-print" class=""  @click="printRendimiento_onClick(rendimientoAcademico)" :loading="loadingPrint"/>
                                    </span>
                                </div>
                            </div>
                            <div class="h-3px w-100 p-0 m-0 bg-primary"/>
                            <div class="flex justify-content-between flex-wrap mt-4 mx-2">
                                <div class="flex mr-1">
                                    <a href="javascript:void(0)" class="font-bold mr-2 text-lg text-gray-800 hover:text-primary" >{{selectedMateria.asignatura.plm_mat_nombre}}</a>
                                </div>
                                <div class="lg:my-0 my-1">
                                    <a href="javascript:void(0)" class="label label-lg  label-light-primary label-inline font-bold ml-2 py-3"> {{selectedMateria.plan.pla_nombre}} </a>
                                    <a href="javascript:void(0)" class="label label-lg label-light-danger label-inline font-semibold ml-1 py-3">{{selectedMateria.ciclo.cil_descripcion}} </a>
                                </div>
                            </div>

                            <!--Materia Detalle -->
                            <div class="flex flex-wrap justify-content-between ">
                                <!-- Col 1 -->
                                <div class="flex flex-column flex-grow-1 ">
                                    <!-- Bar Info -->
                                    <div class="flex flex-wrap mb-2">
                                        <a href="javascript:void(0)" class=" flex align-items-center text-gray-600 font-semibold hover:text-primary sm:mr-5 mb-2"> 
                                            <i class="pi pi-th-large pi-fw  mr-1 text-primary font-bold"></i> {{selectedMateria.facultad.fac_nombre}}</a>
                                        <a href="javascript:void(0)" class="flex align-items-center text-gray-600 font-semibold sm:mr-5 mb-2"> 
                                            <i class="pi pi-shield pi-fw  mr-1 text-primary font-bold"></i> {{selectedMateria.carrera.car_nombre}}</a>
                                        <a href="javascript:void(0)" class="flex align-items-center text-gray-600 font-semibold sm:mr-5 mb-2"> 
                                            <i class="pi pi-tag pi-fw  mr-1 text-primary font-bold"></i> {{selectedMateria.hor_descripcion}}</a>  
                                        <a href="javascript:void(0)" class="flex align-items-center text-gray-600 font-semibold sm:mr-5 mb-2"> 
                                            <i class="pi pi-clock pi-fw  mr-1 text-primary font-bold"></i> {{selectedMateria.hor_comentario}}</a>  
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="col-12 p-3">
                        <div class="flex justify-content-end align-items-center mb-3">
                            <Button  class="p-button-sm btn btn-bg-light text-gray-800 " @click="rendimientoToggle" aria-haspopup="true" aria-controls="overlay_rendimientoMenu">
                                <i class="pi pi-chart-line"></i> 
                                <span class="mx-2">{{rendimientoItems.find(x=> x.clicked).label}}</span>
                                <i class="pi pi-chevron-down"></i> 
                            </Button>

                             <OverlayPanel ref="rendimientoMenu" :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '320px'}" class="custom-overlay" id="overlay_rendimientoMenu">
                                <div class="flex  flex-column">
                                    <div  class="flex flex-shrink-0  " v-for="items in rendimientoItems" :key="items">
                                        <a href="javascript:;" class="btn border-round  w-full hover:bg-blue-50 text-gray-700 flex  align-items-center" @click="rendimientoToggle_onSelected(items)"> 
                                            <LayoutGridIco class="mr-1 flex align-items-center"/> 
                                            <span class=" font-bold ">{{items.label}}</span>
                                        </a>
                                    </div>
                                </div>
                            </OverlayPanel> 
                        </div>
                        <div class="p-card px-4 mb-3" v-for="(seccion,idx_sec) in rendimientoAcademico.revision" :key="idx_sec">
                            <div class="p-card-title ">
                                <div class="card-header w-100 justify-content-center">
                                    <div class="card-title">
                                        <div class="flex flex-column align-items-center">
                                            <h5 class="ml-3 my-0 font-semibold text-gray-900 text-lg flex align-items-center">{{seccion.rse_nombre}}</h5>
                                            <div class="text-gray-500 text-sm font-semibold mx-3"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--Begin: Detalle Seccion -->
                            <!--Begin D1 -->
                            <div class="flex  border-round p-2  border-dashed-custom w-100" v-if="idx_sec == 0">
                                <div class="flex align-items-center  mx-2">
                                    <div class="symbol symbol-50px mr-4"> <i class="text-2xl pi pi-percentage"/>
                                    </div>
                                    <div class="me-2">
                                        <a href="javascript:;" class=" text-gray-700 hover:text-primary font-bold text-lg">Nivel de Avance</a>
                                        <span class="text-gray-400 font-bold block text-sm"></span>
                                    </div>
                                </div>
                                <div class="flex align-items-center mx-2">
                                    <span class="p-badge p-badge-lg p-badge-info align-self-center px-2">{{rendimientoAcademico.rai_avance}}%</span>
                                </div>
                                <span class=" bg-primary mx-3" style="width:2px"></span>
                                <div class="flex flex-column  flex-grow-1">
                                    <h5 class="font-bold text-base mb-1">Descripción del alcance</h5>
                                    <div class="formgrid grid w-full">
                                        <div class="field mb-0  col-12" >
                                            <p class="w-full">{{rendimientoAcademico.rai_descripcion}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                             <!--Begin D2 -->
                            <div class="grid" v-else>
                                <!--Card -->
                                <div class="col-12 md:col-4 ">
                                    <div class="flex border-round p-4 mt-4 border-dashed-custom">
                                        <div class="flex align-items-center flex-grow-1 mx-2">
                                           
                                            <div class="me-2">
                                                <a href="javascript:;" class=" text-gray-700 hover:text-primary font-bold text-lg">Aprobados</a>
                                                <span class="text-gray-400 font-bold block text-sm">Porcentaje según inscripciones</span>
                                            </div>
                                        </div>
                                        
                                        <div class="flex align-items-center">
                                            <span class="text-dark font-bold text-4xl">{{rendimientoAcademico.rai_aprobados}}</span>
                                            <span class="font-bold text-sm text-gray-600 mx-1 pt-1">/</span>
                                            <span class="text-gray-600 font-bold text-sm mr-3 pt-2">{{rendimientoAcademico.rai_inscritos}}</span>
                                            <span class="p-badge p-badge-lg p-badge-primary align-self-center px-2">{{percentFormat(rendimientoAcademico.rai_aprobados,selectedMateria.hor_total_ins)}}%</span>
                                        </div>
                                    </div>
                                </div>
                                <!--Card -->
                                <div class="col-12 md:col-4">
                                    <div class="flex border-round p-4 mt-4 border-dashed-custom">
                                        <div class="flex align-items-center flex-grow-1 mx-2">
                                            <div class="me-2">
                                                <a href="javascript:;" class=" text-gray-700 hover:text-primary font-bold text-lg">Reprobados</a>
                                                <span class="text-gray-400 font-bold block text-sm">Porcentaje según inscripciones</span>
                                            </div>
                                        </div>
                                        <div class="flex align-items-center">
                                            <span class="text-dark font-bold text-4xl">{{rendimientoAcademico.rai_reprobados}}</span>
                                            <span class="font-bold text-sm text-gray-600 mx-1 pt-1">/</span>
                                            <span class="text-gray-600 font-bold text-sm mr-3 pt-2">{{rendimientoAcademico.rai_inscritos}}</span>
                                            <span class="p-badge p-badge-lg p-badge-danger align-self-center px-2">{{percentFormat(rendimientoAcademico.rai_reprobados,selectedMateria.hor_total_ins)}}%</span>
                                        </div>
                                    </div>
                                </div>
                                <!--Card -->
                                <div class="col-12 md:col-4">
                                    <div class="flex border-round p-4 mt-4 border-dashed-custom">
                                        <div class="flex align-items-center flex-grow-1 mx-2">
                                            <div class="me-2">
                                                <a href="javascript:;" class=" text-gray-700 hover:text-primary font-bold text-lg">Deserción</a>
                                                <span class="text-gray-400 font-bold block text-sm">Porcentaje según inscripciones</span>
                                            </div>
                                        </div>
                                        <div class="flex align-items-center">
                                            <span class="text-dark font-bold text-4xl">{{rendimientoAcademico.rai_desercion}}</span>
                                            <span class="font-bold text-sm text-gray-600 mx-1 pt-1">/</span>
                                            <span class="text-gray-600 font-bold text-sm mr-3 pt-2">{{rendimientoAcademico.rai_inscritos}}</span>
                                            <span class="p-badge p-badge-lg p-badge-warning align-self-center px-2">{{percentFormat(rendimientoAcademico.rai_desercion,selectedMateria.hor_total_ins)}}%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--End: Detalle Seccion -->
                            <div class="flex flex-column mt-3" v-for="(criterio,idx_crit) in seccion.criterioList" :key="idx_crit">
                                <h5 class="ml-3 my-0  font-semibold text-gray-900 text-lg flex align-items-center">{{criterio.rce_enunciado}}</h5>
                                <div class="flex flex-column " v-for="(esc, idx_esc) in criterio.escalaList" :key="idx_esc" >
                                    <div class="flex flex-row mb-1 p-2" v-if="esc.selected" style="pointer-events: none;">
                                        <span class=" bg-warning" style="width:4px"></span>
                                        <div class="mx-3 w-full">
                                            <div class="flex">
                                                <div class="form-check form-check-solid">
                                                    <input class="form-check-input" :name="'esc'+idx_sec+'_'+idx_esc+'_'+idx_crit" :checked="esc.selected" type="checkbox" :value="esc"
                                                        @click="selectedEscala_onClick(esc,idx_sec+'_'+idx_esc+'_'+idx_crit)"
                                                    >
                                                </div>
                                                <div class="flex flex-column w-100">
                                                    <a href="javascript:;" class="text-gray-800 font-bold block hover:text-primary">{{esc.ree_descripcion}}</a>                                            
                                                </div>
                                            </div>
                                            <div class="formgrid grid" v-if="esc.selected">
                                                <div class="field  col-12" :class="{'md:col-6': esc.rve_req_comentario}">
                                                    <span class="font-semibold"> Descripción </span>
                                                    <p class="w-full">{{esc.rar_descripcion}}</p>
                                                </div>
                                                <div class="field col-12 md:col-6" v-if=" esc.rve_req_comentario">
                                                    <span class="font-semibold">Estrategia </span>
                                                    <p class="w-full">{{esc.rar_comentario}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <AlertMessage :resource="'RENDIMIENTO'" v-model:type="statusClassRendCP" v-if="IsCreatedComputed">
                            <template #buttons>
                                <a v-if="rendimientoAcademico.rai_estado != 'C'" href="javascript:void(0)" :class="'p-button-'+statusClassRendCP" class=" p-button font-bold ml-1  align-self-center" 
                                @click="sendRendmiento_onClick('C')"> 
                                    <i class="pi pi-checkb pi-pw mr-1" /> Enviar
                                </a>

                                <Button v-else label="Imprimir" icon="" class="ml-1 p-button py-0" :class="'p-button-'+statusClass" :loading="loadingPrint" @click="printRendimiento_onClick(rendimientoAcademico)" v-tooltip.top="'Imprimir'"/>
                            </template>
                        </AlertMessage>
                    </div>

                </div>

            </div>
            
        </div>
    </div>
    <PrintDialog :Title="titlePrint" v-model:Visible="isPrintDialog"  :BLOB="printBlob"  v-if="isPrintDialog" />
    <InformeUpsertDialog :Materia="selectedMateria" :Service="rendimientoAcademicoService" :Codigo="rendimientoAcademico.rai_codigo" 
        v-model:Upsert="upsertInforme" :key="upsertInformeKey" @upsert_onEmit="selectedGrupo_onClick($event)" v-if="upsertInforme"/>


    <Dialog header="Enviar Informe" v-model:visible="displayConfirmation" :style="{width: '350px'}" :modal="true">
        <div class="flex">
            <div class="confirmation-content">
                <i class="pi pi-exclamation-triangle mr-3 text-danger" style="font-size: 2rem" />
            </div>
            <div class="flex flex-column">
                <span>{{messageConfirm}} </span>
                <span class="text-danger" v-for="data in detailConfirm" :key="data">  {{data}} </span>
                </div>
        </div>
            <template #footer>
                <Button label="No" icon="pi pi-times" @click="displayConfirmation =  false" class="p-button-text p-button-danger"/>
                <Button label="Si, Enviar" icon="pi pi-check" @click="changeStatusRendmiento_onClick()" :loading="loadingCreate" class="p-button-primary " autofocus />
            </template>
        </Dialog>
</template>

<script>
import {LayoutGrid} from "@/components/svg/_svgHelper.js"
import GuiaDocenteService from '@/service/guiaDocenteService'
import RendimientoAcademicoService from '@/service/rendimientoAcademicoService'

import AlertMessage from "@/components/cards/AlertMessage"
import PrintDialog from '@/components/Generales/PrintDialog.vue'
import CardEmpleadoInfo  from "@/components/Integracion/CardEmpleadoInfo"
import methods from "@/utilities/generalMethods.js"
import InformeUpsertDialog from "@/components/Integracion/InformeAsignaturaUpsert"

const TABVIEW = {
    DESEMPENO : "Desempeño",
    DESARROLLO : "Informes"
}


export default {
    guiaDocenteService : null,
    rendimientoAcademicoService: null,
    props:{},
    emits:[],
    data(){
        return {
            emp_codigo: 0,
            selectedMateria: {},
            desempenoInformeList : [],

            checkTrue: false,
            loadingPrint: false,
            isPrintDialog: false,
            printBlob: null,
            titlePrint: "",
            VIEW : TABVIEW,
            CURRENT_VIEW : TABVIEW.DESEMPENO,

            rendimientoItems: [],
            rendimientoAcademicoList: [],
            rendimientoAcademico:{ rai_codigo : 0},


            upsertInforme  : false,
            upsertInformeKey : 1,
            displayConfirmation: false,
            messageConfirm: "",
            detailConfirm: []
        }
    },
    created(){
        //List Options Overlay
        this.rendimientoItems = [{ label: "No generado", clicked: true}]
        this.rendimientoAcademicoService = new RendimientoAcademicoService(this.axios);
        this.emp_codigo = this.$store.state.uData.usr_codemp
        this.guiaDocenteService =  new GuiaDocenteService(this.axios)

       
    },
    async mounted(){
        //Informes de desempeño docente y escala de estimacion
        this.desempenoInformeList = await this.guiaDocenteService.getInformeByEmp(this.emp_codigo);
        
    },
    methods:{
        async selectedGrupo_onClick(data){
            this.CURRENT_VIEW =  this.VIEW.DESARROLLO
            this.selectedMateria =  data
            try{
                this.rendimientoAcademicoList = await this.rendimientoAcademicoService.getByHorario(data.hor_codigo,data.ciclo.cil_codigo)
                this.rendimientoItems = []
                
                if(this.rendimientoAcademicoList.length == 0){
                    this.rendimientoAcademico = { rai_codigo : 0}
                    this.rendimientoItems.push({ label: "Generar nuevo", clicked: true, command: () => { this.upsertInforme_onClick(null)}})
                }else{
                    //List Options Overlay
                    this.rendimientoAcademicoList.forEach(element => {
                        this.rendimientoItems.push({
                            label: 'Informe Rendimiento C' + element.rai_computo,
                            clicked:  false,
                            data:  element              
                        })
                    });

                    this.rendimientoItems.push({ label: "Generar nuevo", clicked: false, command: () => { this.upsertInforme_onClick(null)}})

                    this.rendimientoToggle_onSelected(this.rendimientoItems[0])
                }

                
            }catch(ex){
                var msg = methods.messageCode(ex)
                this.$toast.add({severity:'warn', summary:'¡Ocurrió un error!', detail:msg, life: 5000});
            }
        },
        async printInforme_onClick(data){
           try{
                this.loadingPrint =  true;
                this.titlePrint = "Informe de Desempeño " + data.desempenoDocenteList[0].ddo_hor_descripcion
                await this.guiaDocenteService.printInforme(data.ddi_codhor, data.ddi_codcil)
                .then(res=>{
                    this.printBlob = res
                })  
                this.isPrintDialog = true
                
           }catch(ex){
                var msg = methods.messageCode(ex)
                this.$toast.add({severity:'error', summary:'¡OOOPS!', detail:msg, life: 3500});
           }finally{
                
                this.loadingPrint =  false;
           }
        },
    
        

        //#region ******INFORME FINAL ******
        //for overlay list: Tipos de comentarios en observaciones
        rendimientoToggle(event)
        {
            this.$refs.rendimientoMenu.toggle(event);
        },

        async rendimientoToggle_onSelected(item){
            this.rendimientoItems.forEach(x=> x.clicked = false) //set false
            this.rendimientoAcademico = { rai_codigo : 0}
            item.clicked = true
            
           if(!item.data){
                if(this.$refs.rendimientoMenu)
                    this.$refs.rendimientoMenu.hide(); 
               item.command()
                return;
           }
                
            
            if(this.$refs.rendimientoMenu)
                this.$refs.rendimientoMenu.hide(); 
            this.rendimientoAcademico =  item.data           
        },

        async printRendimiento_onClick(data){
           try{
               if(!this.IsCreatedComputed)
                    throw "El informe no ha sido generado. No hay nada que imprimir"
                this.loadingPrint =  true;
                this.titlePrint = "Informe de Rendimiento Acádemico " + data.rai_deschor
                await this.rendimientoAcademicoService.printInforme(data.rai_codigo)
                .then(res=>{
                    this.printBlob = res
                })  
                this.isPrintDialog = true
                
           }catch(ex){
                var msg = methods.messageCode(ex)
                this.$toast.add({severity:'error', summary:'¡OOOPS!', detail:msg, life: 3500});
           }finally{
                this.loadingPrint =  false;
           }
        },
        
        async upsertInforme_onClick(evt){

            if(evt == null){
                // New
            }
            this.upsertInformeKey++;
            this.upsertInforme =  true;
        },

        sendRendmiento_onClick(){
            try{
                if(this.rendimientoAcademico.rai_avance <= 0 || this.rendimientoAcademico.rai_avance >100)
                    throw "El rango de nivel de avance debe ser entre 0% y 100%";
                if(this.rendimientoAcademico.rai_descripcion == "" || !this.rendimientoAcademico.rai_descripcion.trim())
                    throw "Ingrese una descripción válida para la sección de [Desarrollo de la asignatura]";
                else if (!this.rendimientoAcademico.revision.some(x=> x.criterioList.some(c=> c.escalaList.some(x=> x.rar_codigo > 0  && x.rank == 1))))
                     throw "Seleccione al menos un parámetro en [Desarrollo de la asignatura]";
                //  else if (!this.rendimientoAcademico.revision.some(x=> x.criterioList.some(c=> c.escalaList.some(x=> x.rar_codigo > 0  && x.rank == 2))))
                //      throw "Seleccione al menos un parámetro en [Rendimiento Acádemico]";

                this.messageConfirm =  `Se encontrarón las siguientes observaciones: `;
                this.detailConfirm = []
                if(this.rendimientoAcademico.rai_aprobados == 0 || this.rendimientoAcademico.rai_reprobados == 0 || this.rendimientoAcademico.desercion == 0){
                    this.detailConfirm.push(` => Valores de Rendimiento (aprobados, reprobados y/o derserción) es cero.`)
                }

                this.displayConfirmation =  true;
            }catch(ex){
                var msg = methods.messageCode(ex)
                this.$toast.add({severity:'warn', summary:'¡Ocurrió un error!', detail:msg, life: 5000});
            }
        },

        async changeStatusRendmiento_onClick(){
            try{
                this.loadingCreate =  true;
                
                var tempData =  {... this.rendimientoAcademico}
                tempData.rai_estado = 'C'
                await this.rendimientoAcademicoService.Upsert({
                    rendimientoAcademico : tempData,
                    rendimientoRevision : tempData.revision 
                }).then(res => this.rendimientoAcademico = res)

                this.$toast.add({severity:'info', summary:'¡Éxito!', detail:"Enviado con éxito", life: 5000});
            }catch(ex){
                var msg = methods.messageCode(ex)
                this.$toast.add({severity:'warn', summary:'¡Ocurrió un error!', detail:msg, life: 5000});
            }finally{
                this.loadingCreate = false
                this.displayConfirmation =  false
            }
        },
        //#region ******INFORME FINAL******
        /*Format Class */
        percentFormat(val, val2){
            if(val2 == 0){
                return 0
            }

            var div =  ((val/val2) * 100).toFixed(2);
            return div
        },
        

        /*Style Class */
        classTabView(tab)
        {
            return [{'text-primary' : tab === this.CURRENT_VIEW}]
        },
        statusClass(data){
            switch(data.ddi_estado){
                case 'E':
                    return 'warning';
                case 'A':
                    return 'success';
                default:
                    return 'primary';
            }
        },

        
    },
    computed:{
        IsCreatedComputed(){
            return this.rendimientoAcademico.rai_codigo > 0
        },
        statusClassRendCP(){
            switch(this.rendimientoAcademico.rai_estado){
                case 'A':
                    return 'warning';
                default:
                    return 'primary';
            }
        },
    },
    components:{
        AlertMessage,
        "LayoutGridIco" : LayoutGrid,
        CardEmpleadoInfo,
        PrintDialog,
        InformeUpsertDialog
    }
}
</script>
<style>
    .custom-overlay > .p-overlaypanel-content {
         padding: 2px !important; 
    }
</style>
<style scoped>
   
    @media (min-width: 1400px){
        .container {
            max-width: 1320px;
        }
    }
</style>


<style lang="scss" scoped>
    .widget-box {
		border-left: 5px solid;
		&.wb-warning{
			border-left-color:#F8BE5A !important;
			&:nth-child(1){
				color:#F8BE5A
			}
			> .wb-icon {
				padding: 0.5rem;
				background: #FFF4DE;
			}
		}
		&.wb-primary{
			border-left-color:#263680 !important;
			&:nth-child(1){
				color:var(--primary-color);
			}
			> .wb-icon {
				padding: 0.5rem;
				background: #c7dffe;
				color:  var(--primary-color);
			}
		}
		&.wb-danger{
			border-left-color:#ffe1e1 !important;
			&:nth-child(1){
				color: #F64E60 !important;
			}
			> .wb-icon {
				padding: 0.5rem;
				background: #fec7c7;
				color: #F64E60;
			}
		}
		&.wb-info{
			border-left-color:#7986CB !important;
			&:nth-child(1){
				color: #7986CB;
			}
			> .wb-icon {
				padding: 0.5rem;
				background: #7986CB;
				color: #303F9F;
			}
		}
		&.wb-success{
			border-left-color:#1BC3BC !important;
		}
		
	}


</style>
// JSDoc (http://usejsdoc.org/)
/** @module guiaDocenteService */


export default class guiaDocenteService{
    /**
     * @param axios Axios Instance
     */
    constructor(axios){
        this.axios =  axios;
    }

    /**
     * Crear una guia de desempeño docente
     * @param {String} carnet Codigo de carnet
     * @return {JsonObject} JsonObject
     */
    async getConfirmarPerfil(carnet) {
        return this.axios.get(`/GuiaDocente/ConfirmarPerfil/${carnet}`).then(res => res.data);
    }


     /**
     * Crear una guia de desempeño docente
     * @param {int} cil_codigo Codigo de carnet
     * @param {int} cic_codigo Codigo de carnet
     * @param {int} per_codigo Codigo de carnet
     * @return {JsonObject} JsonObject
     */
      async getMateriasInscritas(cil_codigo, cic_codigo, per_codigo) {
        return this.axios.get(`/GuiaDocente/AlumnoInscripcion/${cil_codigo}/${cic_codigo}/${per_codigo}`).then(res => res.data);
    }

    /**
     * Crear una guia de desempeño docente
     * @param {json} data Parámetros de Entrada.
     * @param {Integer} data.codpld  codigo de plan didáctico
     * @param {Integer} data.codemp  Codigo empleado docente
     * @param {String} data.lugar  Descripcion del lugar
     * @param {Integer} data.codhor  Codigo de grupo
     * @param {String} data.hor_descripcion  Descripcion del grupo 
     * @param {Integer} data.est_inscritos  Numero de inscritos en el grupo
     * @param {DateTime} data.fecha_visita  Fecha de visita
     * @param {Integer} data.codreg  Codigo de Regional
     * @param {Integer} data.codcil  Codigo de ciclo
     * @param {Int} data.codigo  Codigo de Guia OUT
     * @return {json} JsonObject
     */
    async createGuiaDesempeno(data){
        return this.axios.post('/GuiaDocente', data, {
            header: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data);
    }
    /**
     * Actualizar un visita par adesempeño docente
     * @param {json} data Parámetros de Entrada.
     * @param {Integer} data.codpld  codigo de plan didáctico
     * @param {String} data.lugar  Descripcion del lugar
     * @param {String} data.tecnica  Tecnica utilizado 
     * @param {String} data.saberes  Saberes formato "000"/"111" 
     * @param {DateTime} data.fecha_visita  Fecha de visita
     * @param {Integer} data.est_presentes  Cantidad de estudiantes presentes
     * @param {Int} data.codigo  Codigo de Guia
     * @return {json} JsonObject
     */
    async updateGuiaDesempeno(codigo,data) {
        return this.axios.put(`/GuiaDocente/${codigo}`, data, {
            header: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data);
    }

    /**
     * Crear una revision de Desempeño
     * @param {json} data Parámetros de Entrada.
     * @param {Integer} data.ddr_codigo Código de revisión
     * @param {Integer} data.ddr_codddo Código de visita => Desempeño Docente
     * @param {Integer} data.ddr_codrve Código de esquema-criterio-escala
     * @param {String} data.ddr_inst Identificador de instrumento => ODD: Observcion Docente EEDD: Escala de Estimación
     * @param {Integer} data.ddr_codusr_creador Código de Usuario
     * @return {json} JsonObject
     */
    async createRevisionDesempeno(data){
        return this.axios.post(`/GuiaDocente/CreateRevision`, data, {
            header: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data);
    }

    /**
     * Obtener resultados de revision de Desempeño
     * @param {Integer} codigo Código de visita => Desempeño Docente
     * @param {String} inst Identificador de instrumento => ODD: Observcion Docente EEDD: Escala de Estimación
     * @return {json} JsonObjectList
     */
    async getRevisionByDesempeno(codigo, inst, per_codigo = 0) {
        return this.axios.get(`/GuiaDocente/GetRevisionByDesempeno/${codigo}/${inst}/${per_codigo}`).then(res => res.data);
    }
    
    async getAllDesempenoDocente(prm) {
        return this.axios.get(`/GuiaDocente/GetAll/`,{
            params: {
                page : prm.page,
                pageSize : prm.pageSize,
                fac_codigo :  prm.fac_codigo,
                car_codigo :  prm.car_codigo,
                ddo_estado :  prm.ddo_estado,
                cil_descripcion:  prm.cil_descripcion,
                searchTerm :  prm.searchTerm
            }
        }).then(res => res.data);
    }

    async getDesempenoDocenteByDocente(emp_codigo, cil_codigo) {
        
        return this.axios.get(`/GuiaDocente/GetByDocente/${emp_codigo}/${cil_codigo}`).then(res => res.data);
    }

    async getDesempenoDocenteByMateria(jsonData) {
        
        return this.axios.get(`/GuiaDocente/GetByMateria/`,{ params :  { jsonData: jsonData} }).then(res => res.data);
    }

    async getDesempenoDocenteById(codigo) {
        return this.axios.get(`/GuiaDocente/GetById/${codigo}`).then(res => res.data);
    }


    //Inforne de resultados
    async getInformeResumen(codhor, cil_codigo) {
        return this.axios.get(`/GuiaDocente/GetInformeResumen/${codhor}/${cil_codigo}`).then(res => res.data);
    }

    async getInformeHorario(codhor, cil_codigo) {
        return this.axios.get(`/GuiaDocente/GetInformeHorario/${codhor}/${cil_codigo}`).then(res => res.data);
    }
    
    async getInformeById(codigo) {
        return this.axios.get(`/GuiaDocente/GetInformeById/${codigo}`).then(res => res.data);
    }

    async getInformeByEmp(codigo) {
        return this.axios.get(`/GuiaDocente/GetInformeByEmp/${codigo}`).then(res => res.data);
    }

    async addToInformeResumen(ddi_codigo, usr_codigo) {
        var config = {
            method: 'post',
            url: `/GuiaDocente/CreateInformeResumen/${ddi_codigo}`,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : usr_codigo
        };

        return  this.axios(config).then(res => res.data);
    }

    async updateInforme(codigo,data) {
        return this.axios.put(`/GuiaDocente/Informe/${codigo}`, data, {
            header: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data);
    }

    async omitirInformeResumen(codigo, data) {
        return this.axios.post( `/GuiaDocente/OmitInformeResumen/${codigo}`, data, {
            header: {
                "Content-Type": "application/json"
            }
        }).then(res => res.data);
    }

    /**
     * Imprimir/Extraer archivo de Informe final (Finalizar)
     * @param {Integer} codhor  Codigo de horario
     * @param {Integer} cil_codigo  codigo de ciclo
     * @return {blob} blobObject
     */
     async printInforme(codhor,cil_codigo ){
        return this.axios({
            url: `/GuiaDocente/Print/${codhor}/${cil_codigo}`,
            method: 'GET',
            responseType: 'blob', // important
        }).then((res) =>res);
    }
    //#region UTILIDADES  y OTROS 
    async getGrupoByDocente(ciclo, empleado) {
        return this.axios.get(`/GuiaDocente/GetGrupoByDocente/${ciclo}/${empleado}`).then(res => res.data);
    }

    async getGrupoByCarrera(ciclo, carrera) {
        return this.axios.get(`/GuiaDocente/GetGrupoByCarrera/${ciclo}/${carrera}`).then(res => res.data);
    }

    async getAllTipoTecnica() {
        return this.axios.get(`/GuiaDocente/GetAllTipoTecnica/`).then(res => res.data);
    }


}